import { defineAsyncComponent } from 'vue'
export { default as AppFooter } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/components/AppFooter.vue";
export const LazyAppFooter = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/components/AppFooter.vue" /* webpackChunkName: "components/app-footer" */).then(c => c.default || c))
export { default as AppHeader } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/components/AppHeader.vue";
export const LazyAppHeader = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/components/AppHeader.vue" /* webpackChunkName: "components/app-header" */).then(c => c.default || c))
export { default as Mail } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/components/Mail.vue";
export const LazyMail = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/components/Mail.vue" /* webpackChunkName: "components/mail" */).then(c => c.default || c))
export { default as IconsEmail } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/components/icons/email.vue";
export const LazyIconsEmail = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/components/icons/email.vue" /* webpackChunkName: "components/icons-email" */).then(c => c.default || c))
export { default as IconsTelephone } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/components/icons/telephone.vue";
export const LazyIconsTelephone = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/components/icons/telephone.vue" /* webpackChunkName: "components/icons-telephone" */).then(c => c.default || c))
export { default as Contactinfo } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/storyblok/Contactinfo.vue";
export const LazyContactinfo = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/storyblok/Contactinfo.vue" /* webpackChunkName: "components/contactinfo" */).then(c => c.default || c))
export { default as Contentheader } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/storyblok/Contentheader.vue";
export const LazyContentheader = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/storyblok/Contentheader.vue" /* webpackChunkName: "components/contentheader" */).then(c => c.default || c))
export { default as Contentsection } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/storyblok/Contentsection.vue";
export const LazyContentsection = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/storyblok/Contentsection.vue" /* webpackChunkName: "components/contentsection" */).then(c => c.default || c))
export { default as Page } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/storyblok/Page.vue";
export const LazyPage = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/storyblok/Page.vue" /* webpackChunkName: "components/page" */).then(c => c.default || c))
export { default as Studentresults } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/storyblok/Studentresults.vue";
export const LazyStudentresults = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/storyblok/Studentresults.vue" /* webpackChunkName: "components/studentresults" */).then(c => c.default || c))
export { default as NuxtWelcome } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/@nuxt/ui-templates/dist/templates/welcome.vue";
export const LazyNuxtWelcome = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/@nuxt/ui-templates/dist/templates/welcome.vue" /* webpackChunkName: "components/nuxt-welcome" */).then(c => c.default || c))
export { default as NuxtLayout } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/app/components/layout";
export const LazyNuxtLayout = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/app/components/layout" /* webpackChunkName: "components/nuxt-layout" */).then(c => c.default || c))
export { default as NuxtErrorBoundary } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/app/components/nuxt-error-boundary";
export const LazyNuxtErrorBoundary = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/app/components/nuxt-error-boundary" /* webpackChunkName: "components/nuxt-error-boundary" */).then(c => c.default || c))
export { default as ClientOnly } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/app/components/client-only";
export const LazyClientOnly = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/app/components/client-only" /* webpackChunkName: "components/client-only" */).then(c => c.default || c))
export { default as DevOnly } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/app/components/dev-only";
export const LazyDevOnly = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/app/components/dev-only" /* webpackChunkName: "components/dev-only" */).then(c => c.default || c))
export { default as ServerPlaceholder } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/app/components/server-placeholder";
export const LazyServerPlaceholder = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/app/components/server-placeholder" /* webpackChunkName: "components/server-placeholder" */).then(c => c.default || c))
export { default as NuxtLink } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/app/components/nuxt-link";
export const LazyNuxtLink = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/app/components/nuxt-link" /* webpackChunkName: "components/nuxt-link" */).then(c => c.default || c))
export { default as NuxtLoadingIndicator } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/app/components/nuxt-loading-indicator";
export const LazyNuxtLoadingIndicator = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/app/components/nuxt-loading-indicator" /* webpackChunkName: "components/nuxt-loading-indicator" */).then(c => c.default || c))
export { default as NuxtPage } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/pages/runtime/page";
export const LazyNuxtPage = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/pages/runtime/page" /* webpackChunkName: "components/nuxt-page" */).then(c => c.default || c))
export { NoScript } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components";
export const LazyNoScript = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/no-script" */).then(c => c['NoScript']))
export { Link } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components";
export const LazyLink = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/link" */).then(c => c['Link']))
export { Base } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components";
export const LazyBase = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/base" */).then(c => c['Base']))
export { Title } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components";
export const LazyTitle = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/title" */).then(c => c['Title']))
export { Meta } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components";
export const LazyMeta = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/meta" */).then(c => c['Meta']))
export { Style } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components";
export const LazyStyle = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/style" */).then(c => c['Style']))
export { Head } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components";
export const LazyHead = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/head" */).then(c => c['Head']))
export { Html } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components";
export const LazyHtml = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/html" */).then(c => c['Html']))
export { Body } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components";
export const LazyBody = /* #__PURE__ */ defineAsyncComponent(() => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/body" */).then(c => c['Body']))
export const lazyGlobalComponents = {
  Contactinfo: LazyContactinfo,
  Contentheader: LazyContentheader,
  Contentsection: LazyContentsection,
  Page: LazyPage,
  Studentresults: LazyStudentresults
}
export const componentNames = ["AppFooter","AppHeader","Mail","IconsEmail","IconsTelephone","Contactinfo","Contentheader","Contentsection","Page","Studentresults","NuxtWelcome","NuxtLayout","NuxtErrorBoundary","ClientOnly","DevOnly","ServerPlaceholder","NuxtLink","NuxtLoadingIndicator","NuxtPage","NoScript","Link","Base","Title","Meta","Style","Head","Html","Body"]