import { default as _91_46_46_46slug_93mlu2SKtOCVMeta } from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/pages/[...slug].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93mlu2SKtOCVMeta?.name ?? "slug",
    path: _91_46_46_46slug_93mlu2SKtOCVMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93mlu2SKtOCVMeta || {},
    alias: _91_46_46_46slug_93mlu2SKtOCVMeta?.alias || [],
    redirect: _91_46_46_46slug_93mlu2SKtOCVMeta?.redirect || undefined,
    component: () => import("/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/pages/[...slug].vue").then(m => m.default || m)
  }
]