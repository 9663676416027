import { getAnalytics, isSupported, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const firebaseConfig = {
    apiKey: "AIzaSyAOLWwmzW0_3StbWyJNouwYrSNWdVznR4U",
    authDomain: "dyslexia-tutoring.firebaseapp.com",
    projectId: "dyslexia-tutoring",
    storageBucket: "dyslexia-tutoring.appspot.com",
    messagingSenderId: "872749919224",
    appId: "1:872749919224:web:6ab211f721672bc8d1040d",
    measurementId: "G-6FH7PS9YR8",
  };

  let app = null;
  let analytics = null;

  isSupported().then((result) => {
    if (result) {
      app = initializeApp(firebaseConfig);
      analytics = getAnalytics(app);
    }
  });

  addRouteMiddleware((to) => {
    isSupported().then((result) => {
      if (result) {
        logEvent(analytics, "page_view", { page_path: to.path });
      }
    });
  });
});
