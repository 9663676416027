import components_plugin_KR1HBZs4kY from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_KlVwwuJRCL from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import firebase_Tc1lVUbp7M from "/Users/cameroncrosby/Dev/dyslexiatutoring_nuxt/plugins/firebase.js";
export default [
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_KlVwwuJRCL,
  chunk_reload_client_UciE0i6zes,
  firebase_Tc1lVUbp7M
]