import { defineNuxtPlugin } from '#app/nuxt'
import { lazyGlobalComponents } from '#components'

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const name in lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, lazyGlobalComponents[name])
      nuxtApp.vueApp.component('Lazy' + name, lazyGlobalComponents[name])
    }
  }
})
