export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":"Dyslexia tutoring using multisensory programme. Dyslexics succeed in reading, writing, and spelling as shown in standardised tests given by registered psychologists"}],"link":[{"rel":"stylesheet","href":"https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap"}],"style":[],"script":[],"noscript":[],"title":"Dyslexia tutoring: information about our tutoring for dyslexia"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = false

export const devPagesDir = null